<template>
  <b-card class="p-2">
    <!-- form -->
    <validation-observer ref="addscheduleForm">
      <b-form @submit.prevent="submitForm">
        <h3 class="mb-2">Edit schedule</h3>
        <b-row>
          <!-- first name -->

          <b-col cols="6">
            <b-form-group label="Day" label-for="Day">
              <validation-provider
                #default="{ errors }"
                name=" Day"
                rules="required"
              >
                <b-form-input
                  disabled
                  id="Day"
                  v-model="schedule.days"
                  placeholder="Day"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Order Type" label-for="ordertype">
              <validation-provider
                #default="{ errors }"
                name=" ordertype"
                rules="required"
              >
                <b-form-input
                  disabled
                  id="ordertype"
                  v-model="schedule.ordertype"
                  placeholder="ordertype"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label="Start Time 1" label-for="starttime1">
              <validation-provider
                #default="{ errors }"
                name=" starttime1"
                rules="required"
              >
                <b-form-timepicker
                  id="starttime1"
                  v-model="schedule.starttime1"
                  placeholder="starttime1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label="End Time 1" label-for="endtime1">
              <validation-provider
                #default="{ errors }"
                name=" endtime1"
                rules="required"
              >
                <b-form-timepicker
                  id="endtime1"
                  v-model="schedule.endtime1"
                  placeholder="endtime1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

            <b-col cols="3">
            <b-form-group label="Start Time 2" label-for="starttime2">
              <validation-provider
                #default="{ errors }"
                name=" starttime2"
                rules="required"
              >
                <b-form-timepicker
                  id="starttime2"
                  v-model="schedule.starttime2"
                  placeholder="starttime2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


            <b-col cols="3">
            <b-form-group label="Start Time 2" label-for="endtime2">
              <validation-provider
                #default="{ errors }"
                name=" endtime2"
                rules="required"
              >
                <b-form-timepicker
                  id="endtime2"
                  v-model="schedule.endtime2"
                  placeholder="endtime2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

             <b-col cols="3">
            <b-form-group label="Interval" label-for="Interval">
              <validation-provider
                #default="{ errors }"
                name=" Interval"
                rules="required"
              >
                <b-form-input
                  id="Interval"
                  v-model="schedule.intervaltime"
                  placeholder="Interval"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <!-- submit and reset -->
          <b-col cols="12" class="text-right">
            <b-button type="submit" requi variant="primary" class="mr-1 mt-1">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormTimepicker,
  BAvatar,
  BCard,
  BListGroup,
  BListGroupItem,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import store from "@/store/index";
import { ref, onUnmounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import usescheduleList from "./usescheduleList";
import { required, integer } from "@validations";
import scheduleStoreModule from "../scheduleStoreModule";
import axios from "@axios";
export default {
  components: {
    BFormTimepicker,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    required,
    integer,
  },
  data() {
    return {
      schedule: {},
      id: null,
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    const promises = [axios.get(`admin/getone/schedule/${this.id}`)];
    const allResponses = await Promise.all(promises);
    this.schedule = allResponses[0].data.data;
  },
  methods: {
    submitForm() {
      this.$refs.addscheduleForm.validate().then(async (success) => {
        if (success) {
          let formData = {
            schedule: this.schedule,
          };
          var result = await this.updateschedule(formData);
          console.log(result);
          setTimeout(() => {
            history.back();
          }, 1000);
        }
      });
    },
  },
  setup() {
    // Register module
    const APP_STORE_MODULE_NAME = "master-schedule";

    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, scheduleStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const { fetchschedule, updateschedule } = usescheduleList();

    return {
      fetchschedule,
      updateschedule,
    };
  },
};
</script>
